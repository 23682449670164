.container {
  width: 100%;
  min-height: 100vh;
  padding: 0 0 3rem 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}

.cultures {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cultureItemContain {
  margin-bottom: 30px;
  width: 60%;
}

.cultureItem {
  margin: 0 1rem;
  width: 100%;
  /* height: 50rem; */
  padding: 1.5rem;
  box-sizing: border-box;
  display: flex;
  position: relative;

  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.cultureItemCover {
  /* margin-top: 20px; */
  width: 50%;
  height: 40rem;
  object-fit: cover;
  object-position: bottom center;
}
.cultureItemCoverL {
  margin-right: 20px;
}
.cultureItemCoverR {
  margin-left: 20px;
}

.cultureItemContent {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.cultureItemTitle {
  font-size: 2rem;
  font-family: LT_H_GBK;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 3.0rem;
}

.cultureItemDes {
  margin-top: 1rem;
  font-size: 1.4rem;
  font-family: LT_R_GBK;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 2rem;
  white-space: pre-line;
}

.bottom {
  width: 100%;
  height: 0.5px;
}
