.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main {
  width: 100%;
  padding: 10rem 7.2rem 0;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.leftIcon {
  margin: 0 4.0rem;
  width: 4.4rem;
  height: 4.4rem;
  opacity: 0.7;
  cursor: pointer;
}

.swiperContain {
  display: flex;
  flex: 1;
  max-width: 100.0rem;
  margin: 0 0 10rem;
}

.ideo {
  width: 30.0rem;
  margin: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.avatars {
  width: 20rem;
  height: 20rem;
}

.avatarContain {
  width: 100%;
  position: relative;
  transition: all 2s ease;
}
.avatar {
  width: 20rem;
  height: 20rem;
  object-fit: contain;
}
.avatarBg {
  position: absolute;
  left: 0;
  top: 0;
  transform: rotateY(180deg);
}

.line {
  margin-top: 1.4rem;
  width: 100%;
  height: 1px;
  opacity: 0.3;
  background-color: #FFFFFF;
}

.name {
  width: 100%;
  text-align: left;
  margin-top: 8px;
  font-size: 1.9rem;
  font-family: SL_JB;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 2.4rem;
}

.des {
  margin-top: 8px;
  font-size: 1.4rem;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 2rem;
}

.downloadContain {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.download {
  padding: 0 1rem;
  height: 3rem;
  border-radius: 1.5rem;
  font-size: 1.4rem;
  font-family: LT_R_GBK;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 2.8rem;
  background-color: #00cde6;
  text-decoration: none;
}

.tags {
  margin-top: 8px;
  font-size: 1.3rem;
  font-family: LT_H_GBK;
  font-weight: normal;
  color: #00CDE6;
  line-height: 1.6rem;
}

.contacts {
  margin-top: 1.0rem;
  display: flex;
  align-items: center;
}
.contactItem {
  margin-right: 8px;
  cursor: pointer;
}
.contactIcon {
  width: 2.5rem;
  height: 2.5rem;
}

.more {
  margin: 1.5rem 0 2.0rem 0;
  height: 3.0rem;
  background: #00CDE6;
  border-radius: 1.5rem;
  display: flex;
  padding: 0 1.3rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.1rem;
  font-family: LT_H_GBK;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 1.1rem;
  letter-spacing: 2px;

  cursor: pointer;
}

@media (max-width: 1000px) {
  .main {
    padding: 0;
  }

  .swiperContain {
    margin: 0;
  }
  
  .ideo {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .avatar {
    width: 100%;
    height: 368px;
    object-fit: cover;
  }

  .line {
    margin-top: 9px;
    width: 100%;
    height: 1px;
    opacity: 0.3;
    background-color: #FFFFFF;
  }

  .name {
    margin-top: 8px;
    font-size: 15px;
    font-family: SL_JB;
    font-weight: normal;
    color: #FFFFFF;
    line-height: 19px;
  }

  .tags {
    margin-top: 8px;
    font-size: 12px;
    font-family: LT_H_GBK;
    font-weight: normal;
    color: #00CDE6;
    line-height: 13px;
  }

  .contacts {
    margin-top: 8px;
    display: flex;
    align-items: center;
  }
  .contactItem {
    margin-right: 8px;
    cursor: pointer;
  }
  .contactIcon {
    width: 25px;
    height: 25px;
  }


  .leftIcon {
    margin: 0 20px;
    width: 25px;
    height: 25px;
    opacity: 0.7;
  }


  .more {
    margin: 15px 0 20px 0;
    height: 30px;
    border-radius: 15px;
    padding: 0 13px;

    font-size: 11px;
  }
}

