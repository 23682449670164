
.navbar {
  width: 100%;
  height: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000000;

  font-size: 1.4rem;
  font-family: LT_H_GBK;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 1.4rem;

  z-index: 100;

  transition: height 0.3s ease-in-out;
}
.placeholder {
  width: 100%;
  height: 6.0rem;
  background-color: #000000;
}

.navbarActive {
  height: 6.0rem;
  overflow: hidden;
}

.navbarM {
  font-size: 14px;
  line-height: 20px;
}
.navbarActiveM {
  height: 60px;
}

.navbar a {
  text-decoration: none;
}

.logo {
  margin-left: 2.3rem;
  width: 8.0rem;
  height: auto;
  border: none;
}
.logoIcon {
  width: 100%;
  height: auto;
}

.moreBtn {
  margin-left: 15px;
}

.tab {
  margin-left: 30px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.tabItem {
  margin: 0 2.0rem;
  color: #FFFFFF;
}
.tabItem:hover {
  color: #00CDE6;
}

.tabItemSelect {
  color: #00CDE6;
}

.contacts {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contactItem {
  margin: 0 4px;
  cursor: pointer;
}

.contactIcon {
  width: 2.7rem;
  height: 2.7rem;
}

.popView {
  z-index: 100;
}

.right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.switchLang {
  margin-right: 2rem;
  width: 10.0rem;
  display: flex;
  justify-content: flex-end;
}
.switchLangBtn {
  padding: 0 12px;
  height: 3.0rem;
  background-color: rgba(60,60,60,0.87);
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow:ellipsis;

  font-size: 1.1rem;
  font-family: LT_H_GBK;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 2px;
}
.switchLangMain {
  width: 14.7rem;
  background-color: rgba(60,60,60,0.87);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.switchLangItem {
  width: 100%;
  height: 4.0rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-size: 12px;
  font-family: LT_H_GBK;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 1.1rem;
  letter-spacing: 2px;
}
.switchLangItemB {
  border-bottom: none;
}
.switchLangItemActive {
  color: #18E6FF;
}
.switchLangArrowDown {
  width: 16px;
  height: 10px;
  transform: rotateZ(180deg);
}
.switchLangArrowUp {
  transform: rotateZ(0);
}

.navbarR {
  margin-right: 5.5rem;
  width: 17.0rem;
  display: flex;
  justify-content: flex-end;
}

.connectBtn {
  padding: 0 9px;
  height: 3.0rem;
  background: #00CDE6;
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap; 
  width: 100%; 
  overflow: hidden;
  text-overflow:ellipsis;

  font-size: 1.1rem;
  font-family: LT_H_GBK;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 2px;
}

.address {
  text-align: center;
  font-size: 1.3rem;
  font-family: LT_H_GBK;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 1.1rem;
  letter-spacing: 2px;
  cursor: pointer;
}

.rightFun {
  width: 14.7rem;
  background-color: #00D6F0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.rightFunItem {
  width: 100%;
  height: 4.0rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-size: 9px;
  font-family: LT_H_GBK;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 1.1rem;
  letter-spacing: 2px;
}
.rightFunItemB {
  border-bottom: none;
}

.moreModal {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.8);
  position: relative;
}
.tabItemM {
  margin: 20px 6px;
  font-size: 20px;
  font-family: LT_H_GBK;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 24px;
  text-decoration: none;
}
.contactTitleM {
  margin-top: 30px;
  font-size: 9px;
  font-family: LT_H_GBK;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 11px;
}
.contactsM {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.contactItemM {
  margin: 0 4px;
}
.contactIconM {
  width: 27px;
  height: 27px;
}


@media (max-width: 1000px) {
  
  .switchLang {
    margin-right: 15px;
    width: auto;
    display: flex;
    justify-content: flex-end;
  }
  .switchLangBtn {
    height: 30px;
    border-radius: 15px;
    font-size: 11px;
  }
  .switchLangMain {
    width: 147px;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .switchLangItem {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    font-size: 9px;
    font-family: LT_H_GBK;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 11px;
    letter-spacing: 2px;
  }
  .switchLangArrowDown {
    width: 16px;
    height: 10px;
    transform: rotateZ(180deg);
  }

  .navbarR {
    margin-right: 1.5rem;
    width: auto;
    display: flex;
    justify-content: flex-end;
  }

  .connectBtn {
    height: 30px;
    border-radius: 15px;
    font-size: 11px;
  }
  
  .address {
    margin-right: 15px;
    text-align: center;
    font-size: 10px;
    font-family: LT_H_GBK;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 11px;
    letter-spacing: 2px;
    cursor: pointer;
  }

  .rightFun {
    width: 147px;
    background-color: #00D6F0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .rightFunItem {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  
    font-size: 9px;
    font-family: LT_H_GBK;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 11px;
    letter-spacing: 2px;
  }
  .rightFunItemB {
    border-bottom: none;
  }

  .closeBtn {
    position: absolute;
    right: 15px;
    top: 15px;
  }
}
