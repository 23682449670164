.home {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section {
  width: 100%;
  background-color: #0B0B0B;
  background-image: url('../../resource/imgs/home/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  /* overflow: hidden; */
}
