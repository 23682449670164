.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0B0B0B;
}

.menu {
  margin: 6.8rem 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.logo {
  margin-top: 3.0rem;
}

.logoIcon {
  width: 15.0rem;
  height: auto;
}

.menuLeft {
  margin-right: 10.0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menuSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.menuSection a {
  cursor: pointer;
}

.line {
  margin: 0 6.8rem;
  width: 1px;
  height: 12.7rem;
  opacity: 0.2;
  background-color: #FFFFFF;
}

.sectionTitle {
  margin-bottom: 4px;
  font-size: 1.8rem;
  font-family: LT_H_GBK;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 3.0rem;
}

.menuSection a {
  text-decoration: none;
}

.item {
  margin-top: 1.5rem;
  max-width: 30rem;
  color: #FFFFFF;
  font-size: 1.6rem;
  font-family: LT_R_GBK;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 2.4rem;
}
.item1 {
  margin: 0;
}


.contacts {
  margin-top: 2.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contactItem {
  margin-right: 8px;
}

.contactIcon {
  width: 2.7rem;
  height: 2.7rem;
}

.bottom {
  margin: 6.0rem 0 2.4rem 0;
  display: flex;
  flex-direction: column;
}
.subscribe {
  font-size: 1.4rem;
  font-family: LT_H_GBK;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 1.7rem;
}
.form {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
}
.emailInputContain {
  width: 25.8rem;
  height: 3.8rem;
  padding: 0 1.1rem;
  background-color: #FFFFFF;
  border-radius: 2px;
}
.emailInput {
  width: 100%;
  height: 3.6rem;
  border: none;

  font-size: 1.1rem;
  font-family: LT_R_GBK;
  font-weight: normal;
  color: #222222;
  line-height: 1.3rem;
}
.emailInput::placeholder {
  font-family: LT_R_GBK;
  color: #C3C3C3;
}
.emailInput:focus {
  outline: none;
  border: none;
}
.join {
  margin-left: 1.8rem;
  width: 8.5rem;
  height: 3.8rem;
  background-color: #FFFFFF;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.1rem;
  font-family: LT_H_GBK;
  font-weight: normal;
  color: #000000;
  line-height: 1.3rem;
}

.icp {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.icpLine {
  width: 100%;
  height: 1px;
  background-color: #FFFFFF;
  opacity: 0.2;
}
.icpText {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-family: LT_R_GBK;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 3rem;
  opacity: 0.5;
}
.terms {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-family: LT_R_GBK;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 3rem;
  opacity: 0.5;
}
.termsLine {
  margin: 0 10px;
}
.termsA {
  color: #FFFFFF;
}

@media (max-width: 1000px) {

  .logo {
    margin: 30px 0;
    margin-right: 0;
  }

  .logoIcon {
    width: 120px;
    height: auto;
  }

  .menu {
    margin: 48px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .line {
    display: none;
  }

  .menuSection {
    margin-bottom: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .menuSection a {
    cursor: pointer;
  }

  .sectionTitle {
    margin-bottom: 5px;
    font-size: 18px;
    font-family: LT_H_GBK;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 24px;
  }

  .menuSection a {
    text-decoration: none;
  }

  .item {
    margin-top: 15px;
    color: #FFFFFF;
    font-size: 15px;
    font-family: LT_R_GBK;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 20px;
  }

  .contacts {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .contactItem {
    margin: 0 4px;
  }

  .contactIcon {
    width: 27px;
    height: 27px;
  }

  .bottom {
    margin: 20px 0 0 0;
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .subscribe {
    font-size: 12px;
    font-family: LT_H_GBK;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 15px;
  }
  .form {
    margin-top: 15px;
    display: flex;
    align-items: center;
  }
  .emailInputContain {
    display: flex;
    flex: 1;
    align-items: center;
    height: 38px;
    padding: 0 11px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    border-radius: 2px;
  }
  .emailInput {
    width: 100%;
    height: 30px;
    border: none;

    font-size: 16px;
    font-family: LT_R_GBK;
    font-weight: normal;
    color: #222222;
    line-height: 13px;
  }
  .emailInput::placeholder {
    font-family: LT_R_GBK;
    color: #C3C3C3;
    font-size: 13px;
  }
  .emailInput:focus {
    outline: none;
    border: none;
  }
  .join {
    margin-left: 12px;
    width: 57px;
    height: 38px;
    background-color: #FFFFFF;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 11px;
    font-family: LT_H_GBK;
    font-weight: normal;
    color: #000000;
    line-height: 13px;
  }
}