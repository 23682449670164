.container {
  width: 100%;
  min-height: 100vh;
  padding-top: 6.0rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  width: 45.7rem;
  font-size: 3.2rem;
  font-family: SL_JB;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 4.0rem;
}

.line {
  margin-top: 3.3rem;
  width: 11.3rem;
  height: 2px;
  background: #00CDE6;
}

.content {
  margin-top: 2.5rem;
  width: 45.7rem;
  height: 20.3rem;
  font-size: 2.0rem;
  font-family: LT_R_GBK;
  /* font-weight: 500; */
  color: #FFFFFF;
  line-height: 3.4rem;
}
.content ul {
  padding: 15px;
}

.center {
  margin-top: 2.0rem;
  margin-left: 3.0rem;
  width: 31.0rem;
  height: 44.0rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  font-size: 1.4rem;
  font-family: SL_JB;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 1.9rem;
  text-align: center;
}

.circleBig {
  margin-top: 4.5rem;
  width: 6.0rem;
  height: 6.0rem;
  border-radius: 3.0rem;
  cursor: pointer;
  background: radial-gradient(#B6F7FF, #00BDD4);
  box-shadow: 0 0 2px 2px rgba(0,201,226,.3);
  -webkit-animation: circle6-ani 4s linear infinite;
  animation: circle6-ani 4s linear infinite;
}

.circleSmall {
  margin-top: -1.0rem;
  margin-right: 14.0rem;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 0.9rem;
  cursor: pointer;
  background: radial-gradient(#B6F7FF, #00C9E2);
  box-shadow: 0 0 1px 1px rgba(0,201,226,.3);
  -webkit-animation: circle5-ani 4s linear infinite;
  animation: circle5-ani 4s linear infinite;
}

.circle4 {
  margin-top: -6.0rem;
  margin-left: 11.0rem;
  width: 11.6rem;
  height: 11.6rem;
  background-image: url('../../resource/imgs/home/img_qiuti4.png');
  background-repeat: no-repeat;
  background-position: center;
	background-size: 100% 100%;
  cursor: pointer;
  -webkit-animation: circle4-ani 8s linear infinite;
  animation: circle4-ani 8s linear infinite;
}

.circle {
  width: 13.0rem;
  height: 13.0rem;
  border-radius: 6.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.35;
  transform: all 0.6s;
  cursor: pointer;
  background: radial-gradient(#00C9E2, #B6F7FF);
  box-shadow: 0 0 6px 6px rgba(0,201,226,.3);
}

.circleSelect {
  opacity: 1;
  /* transform: scale(1.1); */
}

.idols {
  margin-left: 3.0rem;
  -webkit-animation: circle1-ani 4s linear infinite;
  animation: circle1-ani 4s linear infinite;
}

.audiences {
  margin-top: 1.5rem;
  margin-left: -1.0rem;
  margin-right: 3.0rem;
  -webkit-animation: circle2-ani 6s linear infinite;
  animation: circle2-ani 6s linear infinite;
}

.collectors {
  margin-top: 5.0rem;
  margin-left: 1.0rem;
  -webkit-animation: circle3-ani 8s linear infinite;
  animation: circle3-ani 8s linear infinite;
}

.right {
  margin-left: 6.0rem;
  width: 21.6rem;
  height: 38.0rem;
  padding: 2px;
  box-sizing: border-box;
  border: 1px solid #00CDE6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #DDDDDD;
}
.logo {
  width: 21.2rem;
  height: 7.2rem;
}


@media (max-width: 700px) {
  .container {
    width: 100%;
    min-height: 100vh;
    padding-top: 60px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .left {
    margin-top: 0;
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
  }
  .title {
    width: 100%;
    margin-top: 60px;
    font-size: 20px;
    line-height: 30px;
  }
  .line {
    margin-top: 20px;
    width: 113px;
  }
  .content {
    margin: 0;
    width: 100%;
    height: auto;
    font-size: 17px;
    line-height: 24px;
  }
  .content ul {
    margin: 0;
    padding: 15px;
  }

  .mobileBottom {
    margin-top: 20px;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between
  }

  .center {
    margin: 0;
    width: 50%;
    height: auto;
    padding: 0 15px;
    box-sizing: border-box;
  
    font-size: 9px;
    line-height: 12px;
  }

  .circleBig {
    margin-top: 25px;
    margin-left: 20px;
    width: 34px;
    height: 34px;
    border-radius: 17px;
  }
  
  .circleSmall {
    margin-top: 0;
    margin-left: 10px;
    margin-right: 20px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
  }
  
  .circle4 {
    margin-top: 10px;
    margin-left: 10px;
    width: 56px;
    height: 56px;
  }
  
  .circle {
    width: 70px;
    height: 70px;
    border-radius: 35px;
  }
  
  .idols {
    margin-left: 10px;
  }
  
  .audiences {
    margin-top: 15px;
    margin-left: -10px;
    margin-right: 40px;
  }
  
  .collectors {
    margin-top: 30px;
    margin-left: -10px;
  }

  .right {
    margin: 0;
    width: 185px;
    height: 260px;
  }

  .logo {
    width: 106px;
    height: 36px;
  }
}


@-webkit-keyframes circle1-ani {
  25% {
    -webkit-transform: translate(4px 4px);
  }
  50% {
    -webkit-transform: translate(10px, 10px);
  }
  75% {
    -webkit-transform: translate(4px, 4px);
  }
  100% {
    -webkit-transform: translate(0, 0);
  }
}

@keyframes circle1-ani {
  25% {
    -webkit-transform: translate(4px 4px);
  }
  50% {
    -webkit-transform: translate(10px, 10px);
  }
  75% {
    -webkit-transform: translate(4px, 4px);
  }
  100% {
    -webkit-transform: translate(0, 0);
  }
}



@-webkit-keyframes circle2-ani {
  25% {
    -webkit-transform: translate(4px -4px);
  }
  50% {
    -webkit-transform: translate(10px, -10px);
  }
  75% {
    -webkit-transform: translate(4px, -4px);
  }
  100% {
    -webkit-transform: translate(0, 0);
  }
}

@keyframes circle2-ani {
  25% {
    -webkit-transform: translate(4px -4px);
  }
  50% {
    -webkit-transform: translate(10px, -10px);
  }
  75% {
    -webkit-transform: translate(4px, -4px);
  }
  100% {
    -webkit-transform: translate(0, 0);
  }
}

@-webkit-keyframes circle3-ani {
  25% {
    -webkit-transform: translate(-8px -8px);
  }
  50% {
    -webkit-transform: translate(-16px, -16px);
  }
  75% {
    -webkit-transform: translate(-8px, -8px);
  }
  100% {
    -webkit-transform: translate(0, 0);
  }
}

@keyframes circle3-ani {
  25% {
    -webkit-transform: translate(-8px -8px);
  }
  50% {
    -webkit-transform: translate(-16px, -16px);
  }
  75% {
    -webkit-transform: translate(-8px, -8px);
  }
  100% {
    -webkit-transform: translate(0, 0);
  }
}

@-webkit-keyframes circle4-ani {
  25% {
    -webkit-transform: translate(-8px -8px);
  }
  50% {
    -webkit-transform: translate(-16px, -16px);
  }
  75% {
    -webkit-transform: translate(-8px, -8px);
  }
  100% {
    -webkit-transform: translate(0, 0);
  }
}

@keyframes circle4-ani {
  25% {
    -webkit-transform: translate(-8px -10px);
  }
  50% {
    -webkit-transform: translate(-10px, -30px);
  }
  75% {
    -webkit-transform: translate(-8px, -10x);
  }
  100% {
    -webkit-transform: translate(0, 0);
  }
}

@-webkit-keyframes circle5-ani {
  25% {
    -webkit-transform: translate(-3px -3px);
  }
  50% {
    -webkit-transform: translate(-5px, -5px);
  }
  75% {
    -webkit-transform: translate(-3px, -3px);
  }
  100% {
    -webkit-transform: translate(0, 0);
  }
}

@keyframes circle5-ani {
  25% {
    -webkit-transform: translate(-3px -3px);
  }
  50% {
    -webkit-transform: translate(-5px, -5px);
  }
  75% {
    -webkit-transform: translate(-3px, -3px);
  }
  100% {
    -webkit-transform: translate(0, 0);
  }
}

@-webkit-keyframes circle6-ani {
  25% {
    -webkit-transform: translate(1.0rem 3px);
  }
  50% {
    -webkit-transform: translate(2.0rem, 6px);
  }
  75% {
    -webkit-transform: translate(1.0rem, 3px);
  }
  100% {
    -webkit-transform: translate(0, 0);
  }
}

@keyframes circle6-ani {
  25% {
    -webkit-transform: translate(1.0rem 3px);
  }
  50% {
    -webkit-transform: translate(2.0rem, 6px);
  }
  75% {
    -webkit-transform: translate(1.0rem, 3px);
  }
  100% {
    -webkit-transform: translate(0, 0);
  }
}