.container {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.timeline {
  padding: 6.0rem 0;
  width: 100.0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.line {
  position: absolute;
  top: 0;
  left: calc(50% - 1px);
  height: 100%;
  width: 0;
  border: 1px solid;
  border-image: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 2 2;
}

.timelineItem {
  width: 100%;
  display: flex;
}

.itemSpace {
  display: flex;
  flex: 1;
}

.itemL {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
}
.timeContainL {
  display: flex;
  justify-content: flex-end;
  align-items: center;  
}
.itemR {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
}
.timeContainR {
  display: flex;
  justify-content: flex-start;
  align-items: center;  
}

.time {
  margin: 0 1.0rem;
  font-size: 2.4rem;
  font-family: SL_JB;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 3.0rem;
}
.timeActive {
  color: #00CDE6;
}

.finishIcon {
  width: 1.8rem;
  height: 1.8rem;
}

.timelineItemContent {
  margin-top: 1.1rem;
  width: 100%;
  padding: 15px 0;
  box-sizing: border-box;
  background-color: rgba(0,205,230, 0.3);
  border-radius: 1.1rem;

  font-size: 1.9rem;
  font-family: LT_H_GBK;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 3.0rem;
}
.timelineItemContent ul {
  margin: 0;
  padding: 0 0 0 4rem;
  box-sizing: border-box;
}

.nodeCircle {
  margin: 0 3.2rem;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 9.5px;
  border: 1px solid #FFFFFF;
  background-color: #0B0B0B;
  display: flex;
  align-items: center;
  justify-content: center;
}
.node {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #FFFFFF;
}



@media (max-width: 700px) {
  
  .timeline {
    margin: 0 0 45px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .line {
    position: absolute;
    top: 0;
    left: 20px;
    height: 100%;
    border: 1px solid;
    border-image: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 2 2;
  }

  .timelineItem {
    margin-top: 15px;
    width: 100%;
    padding: 0 14px;
    box-sizing: border-box;
    display: flex;
  }

  .itemMobile {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
  }
  .timeContainR {
    display: flex;
    justify-content: flex-start;
    align-items: center;  
  }

  .time {
    margin: 0 10px;
    font-size: 16px;
    font-family: SL_JB;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 20px;
  }
  .timeActive {
    color: #00CDE6;
  }

  .finishIcon {
    width: 12px;
    height: 12px;
  }

  .timelineItemContent {
    margin-top: 11px;
    width: 100%;
    background-color: rgba(0,205,230, 0.3);
    border-radius: 11px;

    font-size: 14px;
    font-family: LT_R_GBK;
    font-weight: normal;
    color: #FFFFFF;
    line-height: 20px;
  }
  .timelineItemContent ul {
    padding: 0 0 0 25px;
  }

  .nodeCircle {
    margin: 3px 20px 0 0;
    width: 12px;
    height: 12px;
    border-radius: 6.5px;
    border: 1px solid #FFFFFF;
    background-color: #0B0B0B;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .node {
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: #FFFFFF;
  }

}