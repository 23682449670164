.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  margin: 20px;
  width: 60%;
  font-size: 2rem;
  color: #fff;
  font-family: LT_R_GBK;
  line-height: 2.4rem;
  text-align: center;
}

.main {
  margin-top: 20px;
  width: 94rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.partner {
  width: 15.1rem;
  height: 15.1rem;
  border: 1px solid #000;
  background-color: #fff;
  box-sizing: border-box;
  cursor: pointer;
}
.partner:hover {
  border: 2px solid #00CDE6;
  transform: scale(1.02);
}

.partnerIcon {
  width: 100%;
  height: 100%;
}