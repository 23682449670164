.container {
  width: 100%;
  min-height: 100vh;
  padding: 0 0 3rem 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.about {
  position: absolute;
  right: 3.8rem;
  top: 8.0rem;
  height: 3.0rem;
  padding: 0 1.4rem;
  background: #00D6F0;
  border-radius: 1.5rem;
}

.aboutText {
  font-size: 1.1rem;
  font-family: LT_H_GBK;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 3.0rem;
  letter-spacing: 2px;
}

.more {
  position: absolute;
  right: 8px;
  top: 120px;
  display: flex;
  align-items: center;
}
.moreText {
  margin-right: 2px;
  font-size: 10px;
  font-family: LT_R_GBK;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 13px;
}

.features {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.featureItem {
  margin: 0 1.1rem;
  width: 44vw;
  height: 40.2rem;
  padding: 1.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;

  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.featureItemMask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  opacity: 0.65;
  z-index: 0;
}

.titleContain {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  z-index: 2;
}

.dot {
  margin-top: 7px;
  width: 8px;
  height: 8px;
  background: #00CDE6;
  border-radius: 4px;
}

.featureTitle {
  font-size: 2.4rem;
  font-family: LT_H_GBK;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 3.0rem;
}

.des {
  margin-top: 1.6rem;
  font-size: 2.0rem;
  font-family: LT_R_GBK;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 2.5rem;
  z-index: 2;
}

.featureItemMore {
  position: absolute;
  right: 8px;
  top: 1.1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.featureItemMoreText {
  margin-right: 2px;
  font-size: 1.5rem;
  font-family: LT_R_GBK;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 2.1rem;
}

.featuresM {
  margin-top: 25px;
  width: 100%;
  padding: 0 11px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.featureItemM {
  margin-bottom: 20px;
  width: 46vw;
  display: flex;
  flex-direction: column;
}
.coverM {
  width: 100%;
  height: 157px;
  object-fit: cover;
}
.titleContainM {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.featureTitleM {
  font-size: 14px;
  font-family: LT_H_GBK;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 21px;
}
.desM {
  margin-top: 6px;  
  font-size: 12px;
  font-family: LT_R_GBK;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 19px;
}

.bottom {
  width: 100%;
  height: 0.5px;
}
