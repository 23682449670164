.container {
  width: 100%;
  height: 100vh;
  padding-top: 6.0rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #000000;
  overflow: hidden;
}

.videoView {
  width: 80%;
  height: 80%;
}

.content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  margin: 0;
  font-size: 8.6rem;
  font-family: LT_H_GBK;
  font-weight: bolder;
  color: #FFFFFF;
  line-height: 9.9rem;
  text-align: center;
  white-space: pre-wrap;
}

.slogan {
  margin: 8px 0 0;
  width: 60%;
  text-align: center;
  font-size: 1.4rem;
  font-family: LT_R_GBK;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 2.6rem;
  letter-spacing: 7px;
}

.downloads {
  margin-top: 3.2rem;
  display: flex;
  align-items: center;
}
.appleStoreIcon {
  width: 17.8rem;
  height: 5.4rem;
  object-fit: contain;
}
.googlePlayIcon {
  margin-left: 2.5rem;
  width: 17.8rem;
  height: 5.4rem;
  object-fit: contain;
}

@media (max-width: 700px) {
  .container {
    padding-top: 60px;
  }
  
  .videoView {
    transform: translateY(-50px);
    width: 100%;
    height: 100%;
  }

  .title {
    font-size: 40px;
    line-height: 50px;
  }

  .slogan {
    width: 90%;
    font-size: 12px;
    line-height: 20px;
  }


  .downloads {
    margin-top: 15px;
    display: flex;
    align-items: center;
  }
  .appleStoreIcon {
    width: 150px;
    height: auto;
    object-fit: contain;
  }
  .googlePlayIcon {
    margin-left: 20px;
    width: 150px;
    height: auto;
    object-fit: contain;
  }
}