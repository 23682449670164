html {
  font-size: 0.72vw;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @font-face {
  font-family: 'LT_R_GBK';
  src: url(./resource/font/FZLanTingHei-R-GBK.woff2);
}

@font-face {
  font-family: 'LT_H_GBK';
  src: url(./resource/font/FZLanTingHei-H-GBK.woff2);
} */

@font-face {
  font-family: 'SL_JB';
  src: url(./resource/font/SupremeLLWeb-Jumbo.ttf);
}
@font-face {
  font-family: 'LT_H_GBK';
  src: url(./resource/font/SupremeLLWeb-Bold.ttf);
}
@font-face {
  font-family: 'LT_R_GBK';
  src: url(./resource/font/SupremeLLWeb-Regular.ttf);
}

.walletconnect-connect__button {
  font-size: 16px !important;
}