.top {
  margin-top: 8.0rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  margin: 7px 6px 0;
  font-size: 2.4rem;
  font-family: LT_H_GBK;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 3.0rem;
}

.dot {
  margin-top: 6px;
  width: 1.0rem;
  height: 1.0rem;
  background: #00CDE6;
  border-radius: 5px;
}

@media (max-width: 1000px) {
  .top {
    margin-top: 80px;
  }
  
  .title {
    margin: 7px 6px 0;
    font-size: 24px;
    line-height: 30px;
  }
  
  .dot {
    margin-top: 6px;
    width: 10px;
    height: 10px;
    background: #00CDE6;
    border-radius: 5px;
  }
}