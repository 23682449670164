.container {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 13.0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.faqs {
  margin-top: 7.5rem;
  display: flex;
  flex-direction: column;
}

.item {
  width: 93.6rem;
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
}

.line {
  margin-top: 1.5rem;
  width: 100%;
  height: 1px;
  opacity: 0.3;
  background-color: #FFFFFF;
}

.questionContain {
  margin-top: 1.5rem;
  width: 100%;
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.question {
  font-size: 1.7rem;
  font-family: LT_H_GBK;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 2.4rem;
}
.extendIcon {
  margin-right: 1.2rem;
  width: 1.5rem;
  height: 1.5rem;
}

.answer {
  margin: 0;
  box-sizing: border-box;
  font-size: 1.5rem;
  font-family: LT_R_GBK;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 3.0rem;
  max-height: 0;
  overflow: hidden;
}
.answerActive {
  transition: max-height 1s ease-in;
  max-height: 60.0rem;
}
.answerLongActive {
  transition: max-height 1.5s ease-in;
  max-height: 100.0rem;
}

@media (max-width: 1000px) {
  .faqs {
    margin-top: 40px;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  
  .item {
    width: 100%;
    display: flex;
    flex-direction: column;
    white-space: pre-wrap;
  }
  
  .line {
    margin-top: 15px;
    width: 100%;
    height: 1px;
    opacity: 0.3;
    background-color: #FFFFFF;
  }
  
  .questionContain {
    margin-top: 15px;
    box-sizing: border-box;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .question {
    font-size: 14px;
    font-family: LT_H_GBK;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 15px;
  }
  .extendIcon {
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }
  
  .answer {
    margin: 0;
    box-sizing: border-box;
    font-size: 12px;
    font-family: LT_R_GBK;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 29px;max-height: 0;
    overflow: hidden;
  }
  .answerActive {
    transition: max-height 0.6s linear;
    max-height: 400px;
  }
  .answerLongActive {
    transition: max-height 0.6s linear;
    max-height: 1000px;
  }
}
